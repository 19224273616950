<template>
    <div>
        <b-card v-if="user_type === 'accounts'">
            <h4 class="mb-2">Filters</h4>
            <b-row>
                <b-col cols="12" md="6">
                    <label>Search</label>
                    <b-form-input v-model="filters.search" class="d-inline-block mr-1" placeholder="Search..."
                        @input="onSearch" />
                </b-col>
                <b-col cols="12" md="3">
                    <label>Invoice Status</label>
                    <v-select v-model="filters.its_id" label="sims_name" :reduce="(item) => item.sims_id"
                        class="per-page-selector d-inline-block w-100" :options="invoice_statuses" @input="onSearch" />
                </b-col>
                <b-col cols="12" md="3">
                    <label>Franchise</label>
                    <v-select v-model="filters.franchise_id" :options="op_franchises.map((franchise) => ({
                        value: franchise.user_id,
                        label: `${franchise.user_firstname} ${franchise.user_lastname}`,
                    }))
                        " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
                        class="per-page-selector d-inline-block w-100" />
                </b-col>
            </b-row>
        </b-card>
        <b-card>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>Invoice ID</th>
                        <th>Student Name</th>
                        <th>Service</th>
                        <th>Plan</th>
                        <th>Transaction Amount</th>
                        <th class="text-center">View Invoice</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="invoices.length === 0">
                        <td colspan="7" class="text-center">No invoices found</td>
                    </tr>
                    <tr v-for="invoice in invoices" :key="invoice.inv_id">
                        <td>{{ invoice.sim_id }}</td>
                        <td>{{ invoice.user_name }}</td>
                        <td>{{ invoice.service_name }}</td>
                        <td>{{ invoice.plan_name }}</td>
                        <td>
                            ₹{{ invoice.transaction_amount }}
                        </td>
                        <td class="text-center">
                            <feather-icon @click="previewInvoice(invoice)" icon="EyeIcon" class="mr-1"></feather-icon>
                        </td>
                    </tr>
                </tbody>
            </table>
        </b-card>
    </div>
</template>

<script>
import AccountsServices from '@/apiServices/AccountsServices';
import CommonServices from '@/apiServices/CommonServices';
import { BFormInput, BButton, BModal, BContainer, BListGroup, BRow, BCol, BCard, BTable, BDropdown, BDropdownItem, BIconThreeDots, BPopover, BListGroupItem, BFormCheckbox } from 'bootstrap-vue';
import vSelect from "vue-select";
import { VBPopover } from 'bootstrap-vue';
import store from "@/store";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import moment from 'moment';

export default {
    components: {
        BFormInput,
        BRow, BCol,
        BTable,
        BDropdown,
        BDropdownItem,
        BIconThreeDots,
        BCard,
        BPopover,
        BListGroup,
        BListGroupItem,
        BModal,
        BContainer,
        vSelect,
        BFormCheckbox,
        BButton
    },
    data() {
        return {
            fields: [
                { key: 'sim_id', label: 'Invoice ID' },
                { key: 'user_name', label: 'Student Name' },
                { key: 'service_name', label: 'Service' },
                { key: 'transaction_amount', label: 'Transaction Amount' },
                { key: 'its_name', label: 'Commission Status' },
                { key: 'actions', label: 'Actions', class: 'text-center' }
            ],
            tableColumns: [
                { key: "showdetails", label: "show" },
                { key: 'month', label: 'Month' },
                { key: 'total_commission_amount', label: 'Total Commission' },
                { key: 'preview', label: 'Invoice' },

            ],
            filters: {
                search: null,
                its_id: null,
                franchise_id: null,
            },
            bank_accounts: [],

            op_franchises: [],
            invoice_statuses: [],
            debounce: null,
            invoices: [],
            clubbed_single_invoices: [],
            workingInvoice: null,
            show_working_invoice: false,
            show_franchise_invoice: false,
            pagination: {
                currentPage: 1,
                perPage: 20,
                perPageOptions: [5, 10, 20, 30],
            },
        };
    },
    computed: {
        user_type() {
            if (store.getters["user/getUserDetails"]?.user_type) {
                return store.getters["user/getUserDetails"].user_type;
            } else {
                return null;
            }
        },
    },
    methods: {
        moment,
        async changeStatus(invoice) {
            const response = await AccountsServices.updateServiceInvoiceStatus({ sim_id: invoice.sim_id, sims_id: invoice.sims_id });
            if (response.data.status) {
                this.getClubbedInvoice()
                this.$toast({
                    component: ToastificationContentVue,
                    props: {
                        title: "Success",
                        icon: "CheckIcon",
                        variant: "success",
                        content: "Invoice status updated successfully",
                    },
                });
            } else {
                this.$toast({
                    component: ToastificationContentVue,
                    props: {
                        title: "Error",
                        icon: "XCircleIcon",
                        variant: "danger",
                        content: "Error updating invoice status",
                    },
                });
            }
        },
        invoiceDetails(item) {
            this.clubbed_single_invoices = item
        },
        previewInvoice(item) {
            this.$router.push({
                name: "Service Invoice",
                params: { student_user_id: item.student_user_id },
            });
        },

        previewClubbedInvoice(invoice_ids) {
            localStorage.setItem('invoice_ids', JSON.stringify(invoice_ids))

            this.$router.push({
                name: "Clubbed Service Invoice",
            });
        },

        async getAllFranchises() {
            try {
                const response = await CommonServices.getAllFranchises();

                if (response.data.status) {
                    this.op_franchises = response.data.data;
                }
            } catch (error) {
                console.error("Error getting getAllCounselors ", error);
            }
        },
        async getInvoiceStatus() {
            try {
                const res = await CommonServices.getServiceInvoiceStatuses()
                if (res.data.status) {
                    this.invoice_statuses = res.data.data
                }
            } catch (error) {
                console.log("Error getting invoice statuses ", error)
            }
        },
        async getClubbedInvoice() {
            try {
                const response = await CommonServices.getServicePurchasedInvoice(this.filters);

                if (response.data.status) {
                    this.invoices = response.data.data.data;
                }
            } catch (error) {
                console.error("Error getting invoices ", error);
            }
        },
        onChange() {
            this.$nextTick(() => {
                this.getClubbedInvoice();
            });
        },
        onSearch() {
            this.pagination.currentPage = 1;
            if (this.debounce) {
                clearTimeout(this.debouce);
                this.debounce = null;
            }
            this.debounce = setTimeout(() => {
                this.onChange();
            }, 1000);
        },
        // async getClubbedInvoice() {
        //     try {
        //         const response = await CommonServices.getClubbedServiceCommissionInvoice(this.filters);
        //         if (response.data.status) {
        //             this.invoices = response.data.data
        //         }
        //     } catch (error) {
        //         console.error("Error getting clubbed invoice", error);
        //     }
        // },
        formatDate(date) {
            return moment(date, 'YYYY-MM').format('MMMM YYYY');
        }
    },
    beforeMount() {
        this.getAllFranchises();
        this.getInvoiceStatus();
        // this.getClubbedInvoice();
        this.getClubbedInvoice();
    }
};
</script>

<style scoped>
.text-center {
    text-align: center;
}
</style>