import { render, staticRenderFns } from "./StudentServiceInvoice.vue?vue&type=template&id=17b5e226&scoped=true&"
import script from "./StudentServiceInvoice.vue?vue&type=script&lang=js&"
export * from "./StudentServiceInvoice.vue?vue&type=script&lang=js&"
import style0 from "./StudentServiceInvoice.vue?vue&type=style&index=0&id=17b5e226&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17b5e226",
  null
  
)

export default component.exports