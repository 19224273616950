<template>
    <div>
        <b-modal :no-close-on-backdrop="true" v-model="show_working_invoice" title="Working Invoice Details" hide-footer
            @ok="" size="lg">
            <b-container v-if="workingInvoice">
                <b-row class="mb-2 align-items-center">
                    <b-col cols="6">
                        <label>First Name</label>
                        <p>{{ workingInvoice.user_firstname }}</p>
                    </b-col>
                    <b-col cols="6">
                        <label>Last Name</label>
                        <p>{{ workingInvoice.user_lastname }}</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2 align-items-center">
                    <b-col cols="6">
                        <label>University Name</label>
                        <p>{{ workingInvoice.university_name }}</p>
                    </b-col>
                    <b-col cols="6">
                        <label>Course Name</label>
                        <p>{{ workingInvoice.course_name }}</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2 align-items-center">
                    <b-col cols="6">
                        <label>Intake Month</label>
                        <p>{{ workingInvoice.intake_month }}</p>
                    </b-col>
                    <b-col cols="6">
                        <label>Course Fee (Per Annum)</label>
                        <p>{{ workingInvoice.course_fee_pa }}</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2 align-items-center">
                    <b-col cols="6">
                        <label>Scholarship Amount</label>
                        <b-form-input :value="workingInvoice.scholarship_amount" disabled></b-form-input>
                    </b-col>
                    <b-col cols="6">
                        <label>Commission Currency</label>
                        <b-form-input :value="workingInvoice.currency" disabled></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="mb-2 align-items-center">
                    <b-col v-if="workingInvoice.commission && workingInvoice.commission.topup_percent">
                        <label>My Commission (%)</label>
                        <b-form-input :value="workingInvoice.commission.topup_percent" disabled></b-form-input>
                    </b-col>
                    <b-col v-else>
                        <label>Market Commission (%)</label>
                        <b-form-input :value="workingInvoice.crm_percent" disabled></b-form-input>
                    </b-col>

                    <b-col cols="6" v-if="user_type === 'accounts'">
                        <label>Bank Account</label>
                        <v-select v-model="workingInvoice.ba_id" v-if="bank_accounts && bank_accounts.length"
                            :options="bank_accounts" label="ba_name" :reduce="(item) => item.ba_id" />
                        <span v-else>No Bank Accounts Available</span>
                    </b-col>

                </b-row>

                <b-row class="mb-2 align-items-center">
                    <b-col cols="6">
                        <label>Commission Amount (in {{ workingInvoice.currency }})</label>
                        <b-form-input v-model="workingInvoice.commission.total_commission"
                            @input="onCommissionType"></b-form-input>
                    </b-col>
                    <b-col cols="6">
                        <label>Commission Amount (in INR)</label>
                        <b-form-input v-model="workingInvoice.commission.inr_commission"
                            @input="onCommissionType"></b-form-input>
                    </b-col>

                </b-row>


                <b-row class="mb-2 align-items-center">
                    <b-col v-if="workingInvoice.bonus_amount">
                        <label>Bonus Commission(Amount)</label>
                        <b-form-input :value="workingInvoice.bonus_amount" disabled></b-form-input>
                    </b-col>
                    <b-col v-if="workingInvoice.bonus_percent">
                        <label>Bonus Commission (%)</label>
                        <b-form-input :value="workingInvoice.bonus_percent" disabled></b-form-input>
                    </b-col>
                </b-row>
                <div class="text-right">
                    <b-button @click="closeModal" variant="outline-danger mr-2">
                        Close
                    </b-button>
                    <b-button variant="outline-success" v-if="workingInvoice.its_id === 1 && user_type === 'accounts'"
                        type="submit" @click.prevent="updateInvoice(workingInvoice)">
                        Approve
                    </b-button>
                    <b-button variant="outline-success" v-if="workingInvoice.its_id === 4" type="submit"
                        @click.prevent="updateFranchiseInvoice(workingInvoice)">
                        Approve
                    </b-button>
                </div>
            </b-container>
        </b-modal>
        <b-modal :no-close-on-backdrop="true" v-model="show_franchise_invoice" title="Working Invoice Details"
            hide-footer @ok="" size="lg">
            <b-container v-if="workingInvoice">
                <b-row class="mb-2 align-items-center">
                    <b-col cols="6">
                        <label>First Name</label>
                        <p>{{ workingInvoice.user_firstname }}</p>
                    </b-col>
                    <b-col cols="6">
                        <label>Last Name</label>
                        <p>{{ workingInvoice.user_lastname }}</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2 align-items-center">
                    <b-col cols="6">
                        <label>University Name</label>
                        <p>{{ workingInvoice.university_name }}</p>
                    </b-col>
                    <b-col cols="6">
                        <label>Course Name</label>
                        <p>{{ workingInvoice.course_name }}</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2 align-items-center">
                    <b-col cols="6">
                        <label>Intake Month</label>
                        <p>{{ workingInvoice.intake_month }}</p>
                    </b-col>
                    <b-col cols="6">
                        <label>Course Fee (Per Annum)</label>
                        <p>{{ workingInvoice.course_fee_pa }}</p>
                    </b-col>
                </b-row>
                <b-row class="mb-2 align-items-center">
                    <b-col cols="6">
                        <label>Scholarship Amount</label>
                        <b-form-input :value="workingInvoice.scholarship_amount" disabled></b-form-input>
                    </b-col>
                    <b-col cols="6">
                        <label>Commission Currency</label>
                        <b-form-input :value="workingInvoice.currency" disabled></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="mb-2 align-items-center">
                    <b-col>
                        <label>Market Commission (%)</label>
                        <b-form-input :value="workingInvoice.crm_percent" disabled></b-form-input>
                    </b-col>
                    <b-col cols="6">
                        <label>Commission Amount</label>
                        <b-form-input :value="Math.floor(workingInvoice.commission.franchise_commission)"
                            @input="onCommissionType"></b-form-input>
                    </b-col>
                </b-row>

                <b-row class="mb-2 align-items-center"
                    v-if="workingInvoice.its_id === 5 && workingInvoice.commission.franchise_commission_inr">
                    <b-col>
                        <label>Partner Commission</label>
                        <p>{{ workingInvoice.commission.franchise_commission_inr }}</p>
                    </b-col>
                </b-row>
                <div class="text-right">
                    <b-button @click="closeModal" variant="outline-danger mr-2">
                        Close
                    </b-button>
                    <b-button :disabled="workingInvoice.its_id === 6" variant="outline-success" type="submit"
                        @click.prevent="updateFranchiseInvoice(workingInvoice)">
                        {{ workingInvoice.its_id === 4 ? 'Update' : (workingInvoice.its_id === 5 ? 'Paid to Partner' :
                            (workingInvoice.its_id === 6 ? 'Paid' : 'Approve')) }}
                    </b-button>
                </div>
            </b-container>
        </b-modal>
        <b-tabs>
            <b-tab :title="user_type === 'accounts' ? 'University' : 'Aliff'">
                <b-card>
                    <h4 class="mb-2">Filters</h4>
                    <b-row>
                        <b-col cols="12" md="6">
                            <label>Search</label>
                            <b-form-input v-model="filters.search" class="d-inline-block mr-1" placeholder="Search..."
                                @input="onSearch" />
                        </b-col>
                        <b-col cols="12" md="3">
                            <label>Invoice Status</label>
                            <v-select v-model="filters.its_id" label="its_name" :reduce="(item) => item.its_id"
                                class="per-page-selector d-inline-block w-100" :options="invoice_statuses"
                                @input="onSearch" />
                        </b-col>
                        <b-col cols="12" md="3">
                            <label>Franchise</label>
                            <v-select v-model="filters.franchise_id" :options="op_franchises.map((franchise) => ({
                                value: franchise.user_id,
                                label: `${franchise.user_firstname} ${franchise.user_lastname}`,
                            }))
                                " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
                                class="per-page-selector d-inline-block w-100" />
                        </b-col>
                    </b-row>
                </b-card>
                <b-card>
                    <b-table id="invoiceListTableID" ref="refInvoiceTable" hover :items="invoices" :fields="fields"
                        responsive empty-text="No matching records found" primary-key="inv_id" show-empty>
                        <template #cell(commission)="data">
                            <div v-if="data.item.crm_percent">
                                {{ data.item.crm_percent }}%
                            </div>
                            <div v-else-if="data.item.crt_percent && user_type === 'accounts'">
                                {{ data.item.crt_percent }}%
                            </div>
                            <!-- <div v-else-if="data.item"></div> -->
                        </template>
                        <template #cell(franchise)="data" v-if="user_type === 'accounts'">
                            <div v-if="data.item.its_id >= 5">
                                <b-badge @click="previewFranchiseInvoice(data.item)">View</b-badge>
                            </div>
                        </template>
                        <template #cell(university)="data" v-if="user_type === 'accounts'">
                            <div v-if="data.item.its_id >= 2">
                                <b-badge @click="previewInvoice(data.item)">View</b-badge>
                            </div>
                        </template>
                        <template #cell(actions)="data">
                            <feather-icon icon="EditIcon" id="popover-reactive-1" class="mr-2"></feather-icon>
                            <b-popover target="popover-reactive-1" triggers="click" placement="left">
                                <b-list-group>
                                    <b-list-group-item button @click="changeStatus(data.item.student_user_id)">
                                        <feather-icon icon="EditIcon" class="mr-1"></feather-icon>
                                        Edit
                                    </b-list-group-item>
                                    <b-list-group-item button @click="previewInvoice(data.item)"
                                        v-if="data.item.its_id >= 2">
                                        <feather-icon icon="EyeIcon" class="mr-1"></feather-icon>
                                        Preview
                                    </b-list-group-item>
                                </b-list-group>
                            </b-popover>
                        </template>
                    </b-table>
                </b-card>
            </b-tab>
            <!-- <b-tab :title="user_type === 'accounts' ? 'Franchise Student' : 'Aliff'">
                <FranchiseUniInvoiceList/>
            </b-tab> -->
            <b-tab :title="user_type === 'accounts' ? 'Franchise Services' : 'Service Package'">
                <ServiceInvoiceList />
            </b-tab>
            <b-tab title="Student Services" v-if="user_type === 'accounts'">
                <StudentServiceInvoice />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import AccountsServices from '@/apiServices/AccountsServices';
import CommonServices from '@/apiServices/CommonServices';
import { BFormInput, BBadge, BTab, BTabs, BButton, BModal, BContainer, BListGroup, BRow, BCol, BCard, BTable, BDropdown, BDropdownItem, BIconThreeDots, BPopover, BListGroupItem } from 'bootstrap-vue';
import vSelect from "vue-select";
import { VBPopover } from 'bootstrap-vue';
import store from "@/store";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import ServiceInvoiceList from './ServiceInvoiceList.vue';
import FranchiseUniInvoiceList from './FranchiseUniInvoiceList.vue';
import StudentServiceInvoice from './StudentServiceInvoice.vue';


export default {
    components: {
        BFormInput,
        BRow, BCol,
        BTable,
        BDropdown,
        BDropdownItem,
        BIconThreeDots,
        BCard,
        BPopover,
        BListGroup,
        BListGroupItem,
        BModal,
        BContainer,
        vSelect,
        BButton,
        BTab, BTabs,
        ServiceInvoiceList,
        FranchiseUniInvoiceList,
        StudentServiceInvoice,
        BBadge
    },
    data() {
        return {
            fields: [
                { key: 'inv_id', label: 'Invoice ID' },
                { key: 'user_name', label: 'Student Name' },
                { key: 'university_name', label: 'University Name' },
                { key: 'commission', label: 'Commission Amount' },
                { key: 'its_name', label: 'Commission Status' },
                { key: 'actions', label: 'Actions', class: 'text-center' }
            ],
            filters: {
                search: null,
                its_id: null,
                franchise_id: null,
            },
            bank_accounts: [],

            op_franchises: [],
            invoice_statuses: [],
            debounce: null,
            invoices: [],
            workingInvoice: null,
            show_working_invoice: false,
            show_franchise_invoice: false,
            pagination: {
                currentPage: 1,
                perPage: 20,
                perPageOptions: [5, 10, 20, 30],
            },
        };
    },
    computed: {
        user_type() {
            if (store.getters["user/getUserDetails"]?.user_type) {
                let user_type = store.getters["user/getUserDetails"].user_type;
                if (user_type === 'accounts') {
                    let temp_arr = this.fields;
                    let col_obj = {
                        key: "franchise",
                        label: "Partner Invoice",
                        sortable: false,
                    };
                    let uni_obj = {
                        key: "university",
                        label: "University Invoice",
                        sortable: false,
                    };
                    if (!temp_arr.some((col) => col.key === col_obj.key)) {
                        temp_arr.push(col_obj);
                    }
                    if (!temp_arr.some((col) => col.key === uni_obj.key)) {
                        temp_arr.push(uni_obj);
                    }
                    this.fields = [...temp_arr];
                }
                return user_type;
            } else {
                return null;
            }
        },
    },
    methods: {
        async changeStatus(student_user_id) {
            await this.getWorkingInvoice(student_user_id);
            this.show_working_invoice = true;
        },
        previewInvoice(item) {
            this.$router.push({
                name: "University Invoice",
                params: { student_user_id: item.student_user_id },
                query: { franchise: false,print }
            });
        },
        previewFranchiseInvoice(item) {
            this.$router.push({
                name: "University Invoice",
                params: { student_user_id: item.student_user_id },
                query: { franchise: true }
            });
        },
        onSearch() {

        },
        async getAllFranchises() {
            try {
                const response = await CommonServices.getAllFranchises();

                if (response.data.status) {
                    this.op_franchises = response.data.data;
                }
            } catch (error) {
                console.error("Error getting getAllCounselors ", error);
            }
        },
        async getInvoiceStatus() {
            try {
                const res = await CommonServices.getInvoiceStatuses()
                if (res.data.status) {
                    this.invoice_statuses = res.data.data
                }
            } catch (error) {
                console.log("Error getting invoice statuses ", error)
            }
        },
        async getInvoices() {
            try {
                const response = await CommonServices.getAllInvoices(this.filters);

                if (response.data.status) {
                    this.invoices = response.data.data;
                }
            } catch (error) {
                console.error("Error getting invoices ", error);
            }
        },
        async getWorkingInvoice(student_user_id) {
            try {
                let res = [];
                res = await AccountsServices.getWorkingInvoice({
                    student_user_id,
                });

                if (res.data.status) {
                    this.workingInvoice = res.data.data;
                    if (this.user_type === "accounts") {
                        this.getBankAccounts();
                    } else {
                        return;
                    }
                }
            } catch (error) {
                console.error("Error getting working invoice ", error);
            }
        },
        async showFranchiseInvoice(student_user_id) {
            try {
                let res = [];
                res = await AccountsServices.getWorkingInvoice({
                    student_user_id,
                    source: "franchise"
                });

                if (res.data.status) {
                    this.workingInvoice = res.data.data;
                    if (this.user_type === "accounts") {
                        this.getBankAccounts();
                        this.show_franchise_invoice = true;
                    } else {
                        return;
                    }
                }
            } catch (error) {
                console.error("Error getting working invoice ", error);
            }
        },
        async updateFranchiseInvoice(invoice) {
            try {
                let res = [];

                res = await AccountsServices.updateWorkingInvoice({
                    inv_id: invoice.inv_id,
                    its_id: invoice.its_id === 4 ? 5 : 6,
                    invoice: invoice
                    // ba_id: invoice.ba_id,
                });
                if (res.data.status) {
                    this.show_working_invoice = false;
                    this.showFranchiseInvoice(invoice.student_user_id);
                    this.$toast({
                        component: ToastificationContentVue,
                        props: {
                            title: "Invoice Approved",
                            icon: "Check",
                            variant: "success",
                        },
                    });
                }
            } catch (error) {
                console.error("Error updating invoice ", error);
            }
        },
        async updateInvoice(invoice) {
            try {
                if (!invoice.ba_id && invoice.its_id === 1) {
                    this.$toast({
                        component: ToastificationContentVue,
                        props: {
                            title: "Bank Account Not Selected",
                            icon: "X",
                            variant: "failure",
                        },
                    });

                    return;
                }
                let res = [];
                if (this.user_type === "accounts") {
                    res = await AccountsServices.updateWorkingInvoice({
                        inv_id: invoice.inv_id,
                        its_id: 3,
                        ba_id: invoice.ba_id,
                    });
                }
                if (this.user_type === "franchise") {
                    res = await AgentServices.updateWorkingInvoice({
                        inv_id: invoice.inv_id,
                        its_id: 5,
                        // ba_id: invoice.ba_id,
                    });
                }
                if (res.data.status) {
                    this.show_working_invoice = false;
                    this.getWorkingInvoice(invoice.student_user_id);
                    this.$toast({
                        component: ToastificationContentVue,
                        props: {
                            title: "Invoice Approved",
                            icon: "Check",
                            variant: "success",
                        },
                    });
                }
            } catch (error) {
                console.error("Error updating invoice ", error);
            }
        },
        async getBankAccounts() {
            try {
                const res = await CommonServices.getAllBanks();
                if (res.data.status) {
                    this.bank_accounts = res.data.data;
                }
            } catch (error) {
                console.error("Error getting bank accounts ", error);
            }
        },
        onChange() {
            this.$nextTick(() => {
                this.getInvoices();
            });
        },
        onSearch() {
            this.pagination.currentPage = 1;
            if (this.debounce) {
                clearTimeout(this.debouce);
                this.debounce = null;
            }
            this.debounce = setTimeout(() => {
                this.onChange();
            }, 1000);
        },
    },
    beforeMount() {
        this.getAllFranchises();
        this.getInvoiceStatus();
        this.getInvoices();
    }
};
</script>

<style scoped>
.text-center {
    text-align: center;
}
</style>